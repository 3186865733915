.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 1rem;
  height: 100%;
  min-height: 400px;
  overflow: auto;
  margin: 0px;
  position: relative;
  background-color: white;
}

.ql-editor {
  overflow: auto;
}

.ql-editing {
  position: relative;
  z-index: 2;
  overflow: auto;
}

.ql-editor .ql-size-small {
  font-size: 0.75em;
}

.ql-editor .ql-size-large {
  font-size: 1.5em;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  /* content: 'Normal'; */
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  /* content: 'Small'; */
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  /* content: 'Large'; */
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  /* content: 'Huge'; */
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 0.75rem;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 1.5rem;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 2.5rem;
}

.ql-editing > input {
  min-height: 100px !important;
  min-width: 200px !important;
}
